<template>
	<div id="main-wrapper" class="inner-pages acct-page comp-plat-page">
		<div class="section banner-holder">

			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Compare Trading Platforms</h2>
				<p>“The More The Merrier”</p>
			</div>
		</div>
		<div class="acct-content compare-accts section">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h6>AAAFx  ensures to provide a one-stop and the best solution for every trader’s needs with
					the multiplicity of trading platforms offered.</h6> <br>
					<p>Every platform comes with the basic fundamentals of fastest execution speed, various customizable options, allowing the use of indicators and expert advisers, and the best available market prices.
					</p>
				</div>
				<div class="table-holder home-table compare-acct wow fadeIn">
					<table>
						<tr>
							<th>Trading Conditions</th>
							<th> <img src="assets/images/comp-ico1.webp" alt="AAAFx" title="AAAFx" width="174" height="45" class="fluid-img" /> </th>
							<th> <img src="assets/images/comp-ico2.webp" alt="AAAFx" title="AAAFx" width="174" height="59" class="fluid-img" /> </th>
							<!-- <th> <img src="assets/images/comp-ico3.webp" alt=""> </th> -->
							<th> <img src="assets/images/comp-ico4.webp"  alt="AAAFx" title="AAAFx" width="101" height="40" class="fluid-img" /> </th>
						</tr>
						<tr>
							<td>Timeframes </td>
							<td>9</td>
							<td>21</td>
							<!-- <td>NA</td> -->
							<td>11</td>
						</tr>
						<tr>
							<td>Leverage</td>
							<td>up to 30X</td>
							<td>up to 30X</td>
							<!-- <td>up to 30X</td> -->
							<td>up to 30X</td>
						</tr>
						<tr>
							<td>Micro-lot trading</td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td>Hedging and Scalping</td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>NA</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td>EAs/Indicators attachment</td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>NA</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td><strong>Other Tools</strong> </td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<!-- <td>&nbsp;</td> -->
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td>Built-in Economic Calendar</td>
							<td>No</td>
							<td>Yes</td>
							<!-- <td>NA</td> -->
							<td>No</td>
						</tr>
						<tr>
							<td>Account Reports Format</td>
							<td>HTML</td>
							<td>HTML, Excel</td>
							<!-- <td>NA</td> -->
							<td>Excel</td>
						</tr>
						<tr>
							<td>Platform's language converter</td>
							<td>No</td>
							<td>No</td>
							<!-- <td>No</td> -->
							<td>Yes</td>
						</tr>
						<!-- <tr>
							<td>ZuluTrade</td>
							<td>No </td>
							<td>No </td>
							<td>Yes </td>
							<td>No </td>
						</tr> -->
						<tr>
							<td>Price Alerts</td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td> <strong>Device Operational Compatibility</strong> </td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<!-- <td>&nbsp;</td> -->
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td class="">Windows Desktop</td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td class="">Mac/iPad</td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>No</td>
						</tr>
						<tr>
							<td class="">Android </td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td class="">iOS </td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td class="">Web-based </td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td> <strong>Suitability</strong> </td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<!-- <td>&nbsp;</td> -->
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td class="">Beginners </td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td class="">Intermediates </td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td class="">Advanced </td>
							<td>Yes</td>
							<td>Yes</td>
							<!-- <td>Yes</td> -->
							<td>Yes</td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td> <a :href="siteurl" target="_blank" class="btn btn-llblue">Open Account</a> </td>
							<td> <a :href="siteurl" target="_blank" class="btn btn-llblue">Open Account</a> </td>
							<!-- <td> <a href="#" class="btn btn-llblue">Open Account</a> </td> -->
							<td> <a :href="siteurl" target="_blank" class="btn btn-llblue">Open Account</a> </td>
						</tr>
					</table>
				</div>
				<div class="title-holder wow fadeIn t-left">
					<h5>Which platform would you like for yourself?</h5> <br>
					<p>That can be a difficult choice. Following is what you can consider:
					</p>
					<ul class="listing-check">
						<li>How easy is the User Interface for you?</li>
						<li>Which platform suits your trading strategies?</li>
						<li>Do you have support available for that platform?</li>
					</ul> <br>
					<p>For the last question, we have got you covered. We provide full technical support in case you face any issues with any platform.

					<br>For the other two questions, you can open a <a :href="siteurl" target="_blank">Demo account</a> and get used to all nuances of our fantastic trading platforms!
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				siteurl : process.env.VUE_APP_CLIENT_REGISTER_URL
			};
		},
	}
</script>